import React from "react"

const SectionTitle = ({ isHero = false, children, className = "" }) => {
  return (
    <>
      {isHero ? (
        <h1
          className={`font-bold font-display text-neutral-900 text-display-md pb-4 laptop:pb-5 tablet:text-display-lg laptop:text-display-xl ${className}`}
        >
          {children}
        </h1>
      ) : (
        <h2
          className={`font-bold font-display text-neutral-800 text-display-sm tablet:text-display-md pb-4 laptop:pb-6 laptop:text-display-lg ${className}`}
        >
          {children}
        </h2>
      )}
    </>
  )
}

export default SectionTitle
