import React from "react"

const SectionText = ({ isHero = false, children, className = "" }) => {
  return (
    <>
      {isHero ? (
        <p
          className={`text-neutral-500 text-body-lg  tablet:text-body-xl tablet:pb-10 pb-7 laptop:pb-14 font-normal ${className}`}
        >
          {children}
        </p>
      ) : (
        <p
          className={`text-neutral-500 text-body-md font-normal ptablet:pb-10 pb-6 laptop:pb-14  tablet:text-body-lg ${className}`}
        >
          {children}
        </p>
      )}
    </>
  )
}

export default SectionText
