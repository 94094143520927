import React from "react"

import SectionText from "../SectionText"
import TextSmall from "../TextSmall"

const FaqCard1 = ({
  title = "Can I change my plan later?",
  text = "Pellentesque erat id amet vel proin aliquam diam. Et non quis tellus elementum nulla quam curabitur. client-focused results.",
  className,
}) => {
  return (
    <>
      <div className={`flex flex-col gap-4 max-w-[388px] ${className}`}>
        {/* card-texts */}
        <div className="flex flex-col gap-3">
          <SectionText
            isHero="false"
            className="!pb-0 !font-medium !text-neutral-700"
          >
            {title}
          </SectionText>
          <TextSmall className="">{text}</TextSmall>
        </div>
      </div>
    </>
  )
}
export default FaqCard1
