import React, { useEffect, useContext } from "react"

import GlobalContext from "../contexts/GlobalContext"
import { headerConfigDefault } from "../components/header/Header"
import { footerConfigDefault } from "../components/Footer"

const PageWrapper = ({
  children,
  headerConfig = headerConfigDefault,
  footerConfig = footerConfigDefault,
}) => {
  const { setHeaderConfig, setFooterConfig } = useContext(GlobalContext)

  useEffect(() => {
    setHeaderConfig({ ...headerConfig })
    setFooterConfig({ ...footerConfig })
  }, [headerConfig, footerConfig, setHeaderConfig, setFooterConfig])

  return <>{children}</>
}

export default PageWrapper
