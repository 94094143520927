import React from "react"
import { Link } from "gatsby"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import FaqCard1 from "../../components/cards/FaqCard1"
import ButtonArrow from "../../components/ButtonArrow"

const Faq1 = ({ className }) => {
  return (
    <>
      <Section className={`bg-neutral-50 ${className}`}>
        <Container>
          {/* section-wrap */}
          <div className="grid grid-cols-1 gap-8 laptop:grid-cols-3 tablet:gap-14">
            {/* left-part */}
            <div className="laptop:col-span-1 col-span-auto ">
              <div>
                <SectionTitle className=" w-full laptop:max-w-[294px]">
                  Common{" "}
                  <span className="text text-primary-600">questions</span>
                </SectionTitle>
                <SectionText className="max-w-[294px] !pb-4">
                  Our team is here to answer any questions you might have.
                </SectionText>
              </div>
              <Link className="inline-block !pr-2" to="/contact-1">
                <ButtonArrow className="inline-block !pr-2">
                  Get in touch
                </ButtonArrow>
              </Link>
            </div>

            {/* right-part */}
            <div className="laptop:col-span-2 col-span-full">
              {/* cards-wrap*/}
              <div className="grid grid-cols-1 tablet:grid-cols-2 gap-9">
                {/* cards */}
                <div className="flex flex-col gap-6 tablet:gap-10 ">
                  {/* card */}

                  <FaqCard1
                    title="Can I change my plan later?"
                    text="Pellentesque erat id amet vel proin aliquam diam. Et non
                      quis tellus elementum nulla quam curabitur. client-focused
                      results."
                  ></FaqCard1>
                  <FaqCard1
                    title="Do you offer custom plans?"
                    text="Quickly communicate enabled technology and turnkey leadership skills. Uniquely enable accurate supply chains rather than frictionless technology."
                  ></FaqCard1>
                  <FaqCard1
                    title="How are installments handled?"
                    text="Objectively integrate enterprise-wide strategic theme areas with functionalized infrastructures.  whereas interdependent quality vectors."
                  ></FaqCard1>
                </div>
                <div className="flex flex-col gap-11">
                  <FaqCard1
                    title="How would I get thing back?"
                    text="Energistically myocardinate clicks-and-mortar testing procedures whereas next-generation manufactured products."
                  ></FaqCard1>
                  <FaqCard1
                    title="Will plans scale as my team grows?"
                    text="Compellingly embrace empowered e-business after user friendly intellectual capital. Interactively  front-end processes with effective convergence."
                  ></FaqCard1>
                  <FaqCard1
                    title="Is there an Education discount?"
                    text="Efficiently enable enabled sources and cost effective products. Completely synthesize principle-centered information after ethical communities."
                  ></FaqCard1>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Faq1
