import React from "react"

import Button from "./Button"
import { ArrowRight } from "react-bootstrap-icons"

const ButtonArrow = ({
  children = "Read article",
  className = "",
  icon = <ArrowRight />,
  ...rest
}) => {
  return (
    <>
      <Button
        className={`!px-0 !bg-transparent !shadow-none hover:text-primary-600 border-none text-neutral-700 focus:ring-0 ${className}`}
        size="xl"
        icon={icon}
        {...rest}
      >
        {children}
      </Button>
    </>
  )
}
export default ButtonArrow
