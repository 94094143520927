import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import PageWrapper from "../components/PageWrapper"
import InnerHero from "../components/InnerHero"
import Faq1 from "../sections/faqs/Faq1"

const FAQPage1 = ({ data }) => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          hasMiddleSocial: true,
          bottomWrapperClassName: "!justify-center",
          bottomClassName: "bg-primary-25",
          className: "bg-primary-25",
        }}
      >
        <GatsbySeo
          title={`Kloft - FAQ 01`}
          description={`We serve globally to modernise static sites to take advantage of the blazing speeds of JAMStack while eliminating monthly maintenance and even probably, server costs.`}
        />
        <InnerHero
          title="FAQ 01"
          text="User generated content in real-time will have multiple touchpoints for offshoring."
        />
        <Faq1 className="!bg-white" />
      </PageWrapper>
    </>
  )
}

export default FAQPage1
